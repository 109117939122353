import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
// import serviceList from '../data/services';
import platform from '../data/platform';

const listServiceReducer = (services = null, action) => {

    if (action.type === 'SERVICE_LIST') {
        return action.payload;
    }
    return services;
};

const listPlatformReducer = () => {
    return platform;
};

export const submittedInquiry = (state = "INQUIRY", action) => {
    switch (action.type) {
        case 'SUBMITTED_INQUIRY':
            {

                return action.payload;
            }
        case 'SUBMITTING_INQUIRY': {
            return action.payload;
        }
        default: return state;
    }
}

////TODO: WHY STATE IS NULL

export default combineReducers({
    listAllService: listServiceReducer,
    listAllPlatform: listPlatformReducer,
    form: formReducer,
    inquiry: submittedInquiry
});