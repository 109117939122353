import React from 'react';
import ReactDOM from 'react-dom';

class Menu extends React.Component {

    render() {
        return (
            <nav>
                <div className="logo">
                    <a href="#"><img src="images/aetos.png" alt="Aetos Consultancy" /></a>
            </div>

                    <div className="menu-toggle">
                        <button type="button" id="menu-btn">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>

                    <ul id="respMenu" className="ace-responsive-menu" data-menu-style="horizontal">
                        <li><a href="#services" className="scroll" data-speed="800">Services</a></li>
                        <li><a href="#contact" className="scroll" data-speed="1000">Contact</a></li>
                    </ul>
        </nav>
                );
            }
}


export default Menu;