import React from 'react';

const footer = () => {
    return (
        <footer>
    	<div className="footer-wrap">
        	<div className="footer">
            	<ul>
                	<li>
                    	<div className="ft-about">
                        <h2>Aetos Consultancy</h2>
                        <p>
                            In today’s rapidly evolving technological environment, a trusted advisor one who not only provides relevant insights, but delivers a combination of strategic vision, proven expertise and practical experience can enhance the value of your business with technology. Our IT Consulting experts provide solutions tailored to your organization’s unique needs.
                            We work with you, providing deep insight into where people, process and technology converge, a clear understanding of the business processes being enabled and a disciplined approach to selecting experts, developing and implementing solutions.
                        </p>
                        <a href="#">Learn more about Aetos Consultancy.</a>
                        </div>
                       
                    </li>
                    <li>
                    	<div className="usefulLink">
                        	<h3>Useful Link</h3>
                        	<ul>
                            	<li><a href="#">Home</a></li>
                                <li><a href="#">Company</a></li>
                            </ul>
                        </div>
                       
                    </li>
                    <li>
                    	<div className="contactBlock">
                        	<h3>Contact with us</h3>
                        	<ul>
                            	<li className="phone">+1 (773) 234 0807</li>
                                <li className="email">info@aetosconsultancy.com</li>
                                <li className="sendMsg-btn"><a href="mailto:info@aetosconsultancy.com">send message</a></li>
                            </ul>
                            <div className="social-icon">
                                <span>Follow Us:</span>
                                <span><a id="facebook" className="mini-icons" target="_blank" href="https://www.facebook.com/AetosConsultancy"></a></span>
                               {/* <span><a id="twitter" className="mini-icons"  href="#" ></a></span>  */}
                                <span><a id="linkedin" className="mini-icons" target="_blank" href="https://www.linkedin.com/company/aetosconsultancy"></a></span>
                                <span><a id="youtube" className="mini-icons" target="_blank" href="https://www.youtube.com/c/AetosConsultancy"></a></span>
                        	</div>
                        	
                        </div>
                       {/*  end contactBlock */}
                       
                    </li>
                </ul>
            </div>
           
            <div className="copyright">
            Copyright © 2024 Aetos Consultancy LLC.
            </div>
        </div>
       
    </footer>
     );
}

export default footer;