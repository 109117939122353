import React from 'react';

const banner = () => {
    return (

        <section className="banner-wrap">
            <div className="inner-banner">
                <div className="banner-txtWrap">
                    <div className="banenr-txt">
                        <h1>Aetos Consultancy</h1>
                        <h2>Invest in Digital Transformation, Innovation and IT Experts.</h2>
                    </div>

                </div>

                <div className="banner-img">
                    <img src="images/banner.svg" alt="" />
                </div>

            </div>
            <img src="images/border-last.png" className="lineA" alt="" />
        </section>
    );
}



export default banner;