import axios from 'axios';
import serviceList from '../data/services';

export const listService = () => {

    // console.log(serviceList);

    return {
        type: 'SERVICE_LIST',
        payload: serviceList
    };
};

export const setValid = (isValid) => {

    return {
        type: 'FORM_VALID',
        payload: isValid
    }
}




export const submitInquiry = (inquiry, getState) => async dispatch => {


    if (inquiry.emailId === undefined) {
        return {
            type: 'SUBMIT_INQUIRY',
            payload: getState
        }
    }

    try {

        dispatch({type:'SUBMITTING_INQUIRY', payload: 'SUBMITTING_INQUIRY'});
        const data = JSON.stringify(inquiry);
        
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
          };

          ////https://service.aetosconsultancy.com/api/v1/email/connect
        const response = await axios.post('https://service.aetosconsultancy.com/api/v1/email', data , {
            headers: {
            'Content-Type': 'application/json'
        }}).catch(function (error) { console.log('umesh'); console.log(error) });
        console.log(response)
        debugger;
        dispatch({
            type: 'SUBMITTED_INQUIRY',
            payload: response.data.message,
        });
    }
    catch{ }

}


