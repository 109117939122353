import React from 'react';
import ReactDOM from 'react-dom';

class ServiceItem extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        var items = this.props.service.serviceItem.map((item) => <li><a>{item.name}</a></li>);
        return (

            <li class="serviceBox-height margin-rt">

                <div class="inner_box">
                    <h3>{this.props.service.serviceType}</h3>
                </div>

                <ul>
                    {items}
                </ul>
                {/* <div class="view-more"><a href="#">View More</a></div> */}
            </li>

        );
    }
}

export default ServiceItem;