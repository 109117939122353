import React from 'react';
import ReactDOM from 'react-dom';

class ServiceSet extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <li className={`${this.props.clsName}`}>
                <a>
                    <div className="service-items">
                        <div className="icon"><img src={this.props.platform.img} alt={this.props.platform.name} /></div>
                        <div className="descr">{this.props.platform.name}</div>
                    </div>
                </a>
            </li>


        );
    }
}

export default ServiceSet;