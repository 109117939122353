import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../actions';

import ReCAPTCHA from 'react-google-recaptcha';

// const  { DOM: { input, select, textarea } } = React
const recaptchaRef = React.createRef();
class RequestForm extends React.Component {

    onSubmit = formValues => {


        // const recaptchaValue = recaptchaRef.current.getValue();
        // //can be invoke action on here too
        // ////TODO: can add recaptcha vlaue in form
        // console.log(recaptchaValue);


        this.props.submitInquiry(formValues);
    }

    renderInquiry() {
        const { handleSubmit, pristine, submitting, invalid } = this.props;

        if (this.props.inquiry === 'SUBMITTING_INQUIRY') {
            return (
                <div className="col-lt">
                    <h1>Thank you for your interest in  <br /> Aetos Consultancy.
                        </h1>
                    <p>Your inquiry is in progress... </p>
                    <div className="box-border gray-bg">
                        <div className="img">
                            <img src="images/man.gif" alt="" />
                        </div>
                    </div>
                </div>

            );
        }
        else if (this.props.inquiry === 'SUBMITTED_INQUIRY') {
            return (
                <div className="col-lt">
                    <h1>Thank you for your interest in  <br /> Aetos Consultancy.
                        </h1>
                    <p>Your inquiry is in placed, you will receive an email shortly and our team will connect you soon. </p>
                    <div className="box-border gray-bg">
                        <div className="img">
                            <img src="images/shake-hand.png" alt="" />
                        </div>
                    </div>
                </div>
            );
        }


        // if (this.props.inquery === 'INQUERY' || this.props.inquery === undefined) {

        else {
            return (
                <div className="col-lt">
                    <h1>Thank you for your interest in  <br /> Aetos Consultancy.
                    </h1>
                    <p>Please provide the following information about your business needs to help us serve you better. You should receive a response within 48 hours. </p>

                    <form onSubmit={handleSubmit(this.onSubmit)} className="formWrap">
                        <ul>
                            <li><Field name="emailId" type="text" component={this.renderInput} type="email" label="Enter your email address" /> </li>
                            <li><Field name="description" component={this.renderInput} label="Short description about your requirement" type="textarea" ></Field></li>
                            <li>
                                <Field name="recaptcha" component={this.renderRecaptchaField} />
                            </li>
                            <li><button type="Submit" name="submit" disabled={pristine || invalid || submitting} className="submit" >Submit </button> </li>
                        </ul>
                    </form>
                </div>
            )
        }

    }


    renderInput({ input, label, type, meta: { error, touched, warning } }) {

        if (touched && error) {
            label = error;

        }

        if (type === "textarea")
        {
            return (
                <div>
                    <textarea {...input} placeholder={label} />
                </div>
            )
        }

        return (

            <div>
                <input {...input} placeholder={label} type={type} />
            </div>

        )
    }

    renderRecaptchaField(field) {
        const { meta: { touched, error } } = field;
        return (
            <div>
                <ReCAPTCHA
                    sitekey="6LfY8OkUAAAAAFiDhSDdoQmYqp1AAhnLWsmyL7id"
                    onChange={field.input.onChange}
                    ref={recaptchaRef}
                />
                <div><p className="text-danger">{touched ? error : ''}</p></div>
            </div>
        );
    }

    render() {
        const { handleSubmit, pristine, submitting, invalid } = this.props;
        return (
            <div className="container">
                <div className="contact-wrap">
                    <div className="contactBox">
                        <div className="col-lt">
                           {this.renderInquiry()}
                        </div>
                        <div className="col-rt"><img src="images/envelope.png" alt="" />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const validate = formValues => {
    const errors = {};
    if (!formValues.emailId) {
        errors.emailId = "Please enter a valid email address.";
    }

    if (!formValues.description) {
        errors.description = "Please write a brief on your requirement.";
    }

    if (!formValues.recaptcha) {
        errors.recaptcha = 'reCAPTCHA required.';
    }

    return errors;

}

const mapStateToProps = (state) => {
    return { inquiry: state.inquiry }
}

export default compose(
    connect(mapStateToProps, actions),

    reduxForm({
        form: 'requestForm',
        validate
        // onSubmit: actions.submitInquery
    })
)(RequestForm);