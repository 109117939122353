import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import ServiceItem from './ServiceItem';
import ServiceSet from './ServiceSet';
import { listService } from '../actions';

class Service extends React.Component {

    componentWillMount() {
               this.props.listService(this.props.allServices);
    }

    componentDidMount() {
        //console.log(this.props);

    }



    renderService() {

        if (this.props.allServices === null) {
            return (<span>loading ...</span>);
        }


        var services = this.props.allServices.services.map((service) => service);
        var items = services.map((service) => <ServiceItem service={service} />)

        return (
            <ul>
                {items}
            </ul>
        );
    }

    renderServiceItem() {

        var items = this.props.allPlatforms.env.map((item, index) => <ServiceSet platform={item} clsName={(index + 1) % 3 == 0 ? "" : "margin-rt"} />);
        return (
            <ul>
                {items}
            </ul>
        );
    }

    render() {
        return (

            <section id="services" className="page-wrap one-page-section">
                <div className="container">
                    <div className="service-wrap">

                        {/* <h1>Invest in Digital Transformation, Inovation and IT Experts. </h1> */}

                        <div className="service-box">
                            {this.renderService()}
                        </div>


                        <div className="service-box">
                            {this.renderServiceItem()}
                        </div>

                    </div>
                </div>

            </section>


        );
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        allServices: state.listAllService,
        allPlatforms: state.listAllPlatform
    };
};
export default connect(mapStateToProps, { listService })(Service);