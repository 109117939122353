import React from 'react';
import Menu from './Menu';
import Banner from './banner';
import Service from './Service';
import RequestForm from './RequestForm';
import Footer from './footer';

import actions from '../actions';

class App extends React.Component {
    
    render() {
        return (
            <div>
                <header>
                    <Menu />
                </header>
                <Banner />
                <Service />
                <section id="contact" className="page-wrap contact-bg">
                   <RequestForm />
                </section>
                <Footer />
            </div>
        );
    }
}

export default App;